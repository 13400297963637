<template>
  <div class="flashcard">
    <!-- Header Section -->
    <div class="header-container">
      <img alt="Vue logo" src="../assets/web_header-flash.png" class="header-image" />
    </div>

    <!-- Item Section -->
    <div class="card-section item-section">
      <input v-model="editedCard.Item" placeholder="Enter Item" class="item-input" />
    </div>

    <!-- Description Section -->
    <div class="card-section description-section">
      <textarea v-model="editedCard.Description" placeholder="Enter Description" class="description-input"></textarea>
    </div>

    <!-- Details Section -->
    <div class="card-section details-section">
      <textarea v-model="editedCard.Details" placeholder="Enter Details" class="details-input"></textarea>
    </div>

    <!-- Image Section -->
    <div class="card-section">
      <div class="image-container">
        <img
          :src="editedCard.Image || fallbackImage"
          @click="changeImage"
          alt="Card image"
          class="card-image"
        />
        <p v-if="!editedCard.Image">Click to add an image</p>
      </div>
      <input type="file" @change="onImageUpload" style="display: none;" ref="fileInput" />
    </div>

    <!-- Modal for Image Options -->
    <div v-if="showImageOptions" class="image-modal">
      <div class="modal-content">
        <h3>Change Image</h3>
        <button @click="keepOriginalImage">Keep Original</button>
        <button @click="selectLocalImage">Choose Local Image</button>
        <button @click="generateAIImage">Generate AI Image</button>
        <button @click="closeImageOptions">Cancel</button>
      </div>
    </div>

    <!-- Dropdowns for Category and Subcategory -->
    <div class="category-row">
      <button @click="toggleCategoryDropdown" class="select-category-button">
        {{ selectedCategory || 'Category' }}
      </button>
      <button
        @click="toggleSubcategoryDropdown"
        class="select-category-button"
        :disabled="!selectedCategory"
      >
        {{ selectedSubcategory || 'Subcategory' }}
      </button>
      <button @click="createCardDetails" class="control-button dark-green">
        Create Card
      </button>
    </div>

    <!-- Dropdown Content -->
    <div v-if="showingCategory" class="dropdown-content">
      <input
        type="text"
        v-model="categorySearch"
        placeholder="Search Category"
        class="search-input"
      />
      <select v-model="selectedCategory" @change="selectCategory">
        <option value="" disabled>Select Category</option>
        <option
          v-for="category in filteredCategories"
          :key="category"
          :value="category"
        >
          {{ category }}
        </option>
      </select>
    </div>
    <div v-if="showingSubcategory" class="dropdown-content">
      <input
        type="text"
        v-model="subcategorySearch"
        placeholder="Search Subcategory"
        class="search-input"
      />
      <select v-model="selectedSubcategory" @change="selectSubcategory">
        <option value="" disabled>Select Subcategory</option>
        <option
          v-for="subcategory in filteredSubcategories"
          :key="subcategory"
          :value="subcategory"
        >
          {{ subcategory }}
        </option>
      </select>
    </div>

    <!-- Navigation and Action Buttons -->
    <div class="controls">
      <button @click="previousCard" class="control-button orange">Previous</button>
      <button @click="nextCard" class="control-button orange">Next</button>
    </div>
    <div class="controls">
      <button
        @click="deleteCard"
        :disabled="!editedCard || !editedCard.firestoreId"
        class="control-button red"
      >
        Delete Card
      </button>
      <button @click="deleteCategory" class="control-button red">Delete Cat</button>
      <button @click="deleteSubcategory" class="control-button red">Delete Subcat</button>
    </div>
    <div class="controls">
      <button @click="createNewCard" class="control-button green">New</button>
      <button @click="updateCard" class="control-button purple">Update</button>
    </div>
  </div>
</template>


<script>
// Importing necessary Firebase functions
import { auth } from '../firebaseConfig';
import {
  collection,
  query,
  where,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  addDoc,
  getFirestore,
  onSnapshot
} from 'firebase/firestore';
//import axios from 'axios';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
//import createPersistedState from 'vuex-persistedstate';
import { setLogLevel } from "firebase/firestore";
setLogLevel("log");
//import { clearIndexedDbPersistence } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const db = getFirestore();

export default {

  data() {

    return {
      user: null, // Current authenticated user
      idToken: null, // Firebase ID Token
      categories: [], // Categories loaded dynamically
      subcategories: [], // Subcategories loaded dynamically
      categorySearch: '', // Search field for category
      subcategorySearch: '', // Search field for subcategory
      currentCard: {Item: '', Description: '', Details: '', Image: ''},
      selectedCategory: '',
      selectedSubcategory: '',
      showingCategory: false,
      showingSubcategory: false,
      showImageOptions: false, // Control visibility of the image options modal
      editedCard: {
        Item: '',
        Description: '',
        Details: '',
        Image: '', // Holds the image URL
      },
      fallbackImage: '../assets/default_image.png', // Path to a default placeholder image
    };
  },

  computed: {
    // Alphabetically sorted and filtered categories based on the search input
    filteredCategories() {
      return this.categories
          .filter(category => category.toLowerCase().includes(this.categorySearch.toLowerCase()))
          .sort((a, b) => a.localeCompare(b));
    },
    // Alphabetically sorted and filtered subcategories based on the search input
    filteredSubcategories() {
      return this.subcategories
          .filter(subcategory => subcategory.toLowerCase().includes(this.subcategorySearch.toLowerCase()))
          .sort((a, b) => a.localeCompare(b));
        },
    editedCategory: {
        get() {
          return this.editedCard?.Category || "";
        },
        set(value) {
          this.editedCard.Category = value;
        },
      },
      editedSubcategory: {
        get() {
          return this.editedCard?.Subcategory || "";
        },
        set(value) {
          this.editedCard.Subcategory = value;
        },
      },
  },

  methods: {

    // Authenticate user and retrieve ID Token
    async authenticateUser() {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          this.user = user;
          this.idToken = await user.getIdToken();
          console.log('User authenticated:', user.email);
        } else {
          this.user = null;
          this.idToken = null;
          console.warn('User not authenticated.');
        }
      });
    },
    // Load categories dynamically with token validation
    async loadCategories() {
      try {
        // Fetch all cards from Firestore
        const cardsCollection = collection(db, 'cards');
        const snapshot = await getDocs(cardsCollection);

        // Extract unique categories
        const categoriesSet = new Set();
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          if (data.Category) {
            categoriesSet.add(data.Category);
          }
        });

        // Update the categories list
        this.categories = Array.from(categoriesSet);

        // Log the updated categories
        console.log('Categories loaded and updated:', this.categories);

        // Automatically select the first category (optional)
        //if (!this.selectedCategory && this.categories.length > 0) {
        //  this.selectedCategory = this.categories[0];
        //  await this.loadSubcategories(this.selectedCategory);
        //}
      } catch (error) {
        console.error('Error loading categories:', error);
      }
    },


    // Load cards by category and subcategory with real-time updates
    loadCards(category, subcategory) {
      try {
        // Ensure category and subcategory are provided
        if (!category || !subcategory) {
          console.error("Category or Subcategory missing for card query.");
          return;
        }

        console.log(`Loading cards for Category: ${category}, Subcategory: ${subcategory}`);

        // Reference the 'cards' collection
        const cardsCollection = collection(db, "cards");

        // Query for matching cards based on Category and Subcategory
        const cardsQuery = query(
            cardsCollection,
            where("Category", "==", category),
            where("Subcategory", "==", subcategory)
        );

        // Use onSnapshot for real-time updates
        onSnapshot(cardsQuery, (snapshot) => {
          this.cards = snapshot.docs.map((doc) => ({
            firestoreId: doc.id, // Firestore document ID
            ...doc.data(),
          }));

          console.log("Fetched cards:", this.cards);

          // Handle case when no cards are found
          if (this.cards.length === 0) {
            console.warn("No cards found for the specified category and subcategory.");
            this.editedCard = null; // Clear the editedCard state
            this.currentCardIndex = -1; // No valid card to index
          } else {
            // Set the first card as the active card if none is set
            if (!this.editedCard || !this.cards.find(card => card.id === this.editedCard.id)) {
              this.currentCardIndex = 0;
              this.editedCard = {...this.cards[0]}; // Default to the first card
            } else {
              // Keep the existing editedCard if it is still valid
              this.currentCardIndex = this.cards.findIndex(card => card.id === this.editedCard.id);
            }
          }
        });
      } catch (error) {
        console.error("Error loading cards:", error);
      }
    },


    // Load subcategories dynamically based on selected category
    async loadSubcategories(category) {
      try {
        const cardsCollection = collection(db, 'cards');
        const q = query(cardsCollection, where('Category', '==', category));
        const snapshot = await getDocs(q);
        const subcategoriesSet = new Set();
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          if (data.Subcategory) {
            subcategoriesSet.add(data.Subcategory);
          }
        });
        this.subcategories = Array.from(subcategoriesSet);
        console.log('Subcategories loaded for category:', category, this.subcategories);
      } catch (error) {
        console.error('Error loading subcategories:', error);
      }
    },

    // Toggle category dropdown visibility
    toggleCategoryDropdown() {
      this.showingCategory = !this.showingCategory;
      this.showingSubcategory = false;
    },

    // Toggle subcategory dropdown visibility
    toggleSubcategoryDropdown() {
      this.showingSubcategory = !this.showingSubcategory;
      this.showingCategory = false;
    },

    // Handle category selection
    async selectCategory() {
      this.selectedSubcategory = ''; // Reset subcategory selection
      this.showingCategory = false;
      console.log('Selected category:', this.selectedCategory);

      // Load subcategories when a category is selected
      if (this.selectedCategory) {
        await this.loadSubcategories(this.selectedCategory);
      }
    },

    async selectSubcategory(event) {
      this.selectedSubcategory = event.target.value || this.subcategories[0];
      this.showingSubcategory = false;
      console.log('Selected subcategory:', this.selectedSubcategory);

      // Load cards for the selected category and subcategory
      if (this.selectedCategory && this.selectedSubcategory) {
        await this.loadCards(this.selectedCategory, this.selectedSubcategory);
      }
    },
    async createCardDetails() {
        if (!this.selectedCategory || !this.selectedSubcategory || !this.editedCard.Item) {
            alert("Category, Subcategory, and Item are required to create card details.");
            return;
        }

        try {
            const response = await fetch('https://www.smartflashprime.au/run-script', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: this.user.email,
                    username: this.user.displayName || "Anonymous",
                    category: this.selectedCategory,
                    subcategory: this.selectedSubcategory,
                    num_cards: 1,
                    card_type: "Information",
                    item: this.editedCard.Item,
                }),
            });

            const text = await response.text();
            console.log("Raw API response text:", text);

            const result = JSON.parse(text);
            if (result.status === 'pending') {
                alert("Request sent to create card details. Task ID: " + result.task_id);
                await this.loadNewCardDetails();
            } else if (result.status === 'failure') {
                alert(`Failed to create card details: ${result.message}`);
            } else {
                alert("Unexpected API response format.");
            }
        } catch (error) {
            console.error("Error creating card details:", error);
            alert(`An error occurred while creating the card details: ${error.message || error}`);
        }
    },

    async loadNewCardDetails(retries = 5, delay = 2000) {
        try {
            // Poll Firestore to find the newly created card
            for (let attempt = 0; attempt < retries; attempt++) {
                const cardsCollection = collection(db, "cards");
                const q = query(
                    cardsCollection,
                    where("Category", "==", this.selectedCategory),
                    where("Subcategory", "==", this.selectedSubcategory),
                    where("Item", "==", this.editedCard.Item) // Match the specific item
                );

                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0]; // Assuming one match
                    this.editedCard = {
                        firestoreId: doc.id,
                        ...doc.data(),
                    };
                    console.log("New card loaded:", this.editedCard);
                    return; // Exit the loop as the card is found
                } else {
                    console.warn("New card not found, retrying...");
                    await new Promise(resolve => setTimeout(resolve, delay)); // Wait before retrying
                }
            }

            // If no card is found after retries, show a warning
            console.warn("New card not found in Firestore after retries.");
            alert("Failed to load the new card. Please refresh manually.");
        } catch (error) {
            console.error("Error loading new card:", error);
            alert("An error occurred while loading the new card.");
        }
    },

    async refreshCurrentCard() {
      if (!this.editedCard || !this.editedCard.firestoreId) {
        console.warn("No card to refresh.");
        return;
      }

      try {
        const cardRef = doc(db, "cards", this.editedCard.firestoreId);
        const docSnap = await getDoc(cardRef);

        if (docSnap.exists()) {
          this.editedCard = {
            firestoreId: docSnap.id,
            ...docSnap.data(),
          };
          console.log("Card refreshed:", this.editedCard);
        } else {
          console.warn("Card no longer exists.");
          alert("Card no longer exists.");
        }
      } catch (error) {
        console.error("Error refreshing card:", error);
        alert("Failed to refresh the card. Please try again.");
      }
    },

    previousCard() {
      if (this.currentCardIndex === 0) {
        this.currentCardIndex = this.cards.length - 1;
      } else {
        this.currentCardIndex -= 1;
      }
      this.editedCard = {...this.cards[this.currentCardIndex]};
      console.log("Previous card:", this.editedCard);
    },

    nextCard() {
      this.currentCardIndex = (this.currentCardIndex + 1) % this.cards.length;
      this.editedCard = {...this.cards[this.currentCardIndex]};
      console.log("Next card:", this.editedCard);
    },

    /*
  // Image upload handler
  onImageUpload(event) {
    const file = event.target.files[0];
    if (file) {
      this.backImage = URL.createObjectURL(file);
      this.editedCard.Image = this.backImage; // Update the image URL
    }
  },
*/
    // Trigger the modal with image options
    changeImage() {
      this.showImageOptions = true; // Show the modal
    },

    // Close the modal
    closeImageOptions() {
      this.showImageOptions = false;
    },

    // Keep the original image
    keepOriginalImage() {
      this.closeImageOptions();
      alert("Original image kept.");
    },

    // Open file picker for local media selection
    selectLocalImage() {
      this.closeImageOptions();
      this.$refs.fileInput.click(); // Trigger the file input
    },

    async generateAIImage() {
        if (!this.editedCard.Item || !this.editedCard.Description) {
            alert("Item and description are required to generate an image.");
            return;
        }

        try {
            const response = await fetch('https://www.smartflashprime.au/generate-image', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    item: this.editedCard.Item,
                    description: this.editedCard.Description,
                    category: this.selectedCategory,
                    subcategory: this.selectedSubcategory,
                }),
            });

            const text = await response.text();
            console.log("Raw API response text:", text);

            const result = JSON.parse(text);
            if (result.status === 'success') {
                this.editedCard.Image = result.image_url;
                alert("AI Image generated successfully.");
            } else if (result.status === 'failure') {
                alert(`Failed to generate AI image: ${result.message}`);
            } else {
                alert("Unexpected API response format.");
            }
        } catch (error) {
            console.error("Error generating AI image:", error);
            alert(`An error occurred while generating the image: ${error.message || error}`);
        }
    },

    onImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async () => {
          const imageUrl = reader.result;
          try {
            const firebaseUrl = await this.saveImageToFirebase(imageUrl);
            this.editedCard.Image = firebaseUrl; // Update the card's Image field with the uploaded image URL
          } catch (error) {
            console.error("Error uploading image to Firebase:", error);
          }
        };
        reader.readAsDataURL(file);
      }
    },

    async saveImageToFirebase(imageUrl) {
      try {
        const response = await fetch(imageUrl);
        if (!response.ok) throw new Error(`Failed to fetch image: ${response.status}`);
        const blob = await response.blob();

        const category = encodeURIComponent(this.selectedCategory || 'default_category');
        const subcategory = encodeURIComponent(this.selectedSubcategory || 'default_subcategory');
        const itemName = encodeURIComponent(this.editedCard.Item || 'default_item');
        const timestamp = Date.now();
        const storagePath = `smartflash_objects/images/${category}/${subcategory}/${itemName}_${timestamp}.png`;

        const storage = getStorage(); // Firebase storage instance
        const imageRef = ref(storage, storagePath);
        await uploadBytes(imageRef, blob);
        const downloadUrl = await getDownloadURL(imageRef);
        console.log(`Image successfully uploaded to Firebase: ${downloadUrl}`);

        this.editedCard.Image = downloadUrl; // Update the card's Image field
        alert("Image uploaded successfully.");

        return downloadUrl;
      } catch (error) {
        console.error("Error saving image to Firebase:", error);
        alert("Failed to upload the image.");
        throw error;
      }
    },

    async deleteCard() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          console.error("No authenticated user found. Cannot proceed with deletion.");
          alert("You must be signed in to delete a card.");
          return;
        }

        if (!this.editedCard || !this.editedCard.firestoreId) {
          alert("No card selected to delete or Firestore ID is missing.");
          return;
        }

        const confirmDelete = confirm(`Are you sure you want to delete the card: ${this.editedCard.Item}?`);
        if (!confirmDelete) return;

        const cardRef = doc(db, "cards", this.editedCard.firestoreId);
        await deleteDoc(cardRef);

        console.log(`Document successfully deleted from Firestore: ${this.editedCard.firestoreId}`);

        // Find the index of the deleted card
        const deletedCardIndex = this.cards.findIndex((card) => card.firestoreId === this.editedCard.firestoreId);

        // Remove the deleted card from the array
        this.cards.splice(deletedCardIndex, 1);

        // Determine the next card to display
        if (this.cards.length > 0) {
          // Wrap around if the deleted card was the last one
          const nextCardIndex = deletedCardIndex >= this.cards.length ? 0 : deletedCardIndex;
          this.currentCardIndex = nextCardIndex;
          this.editedCard = { ...this.cards[nextCardIndex] };

          console.log("Next card set as active:", this.editedCard);
        } else {
          // No cards left
          this.editedCard = null;
          this.currentCardIndex = -1;
          alert("Card deleted successfully. No more cards in the list.");
        }

        alert("Card deleted successfully.");
      } catch (error) {
        console.error("Error deleting the card:", error);
        alert("Failed to delete the card. Please try again.");
      }
    },

    // Delete the entire category
    async deleteCategory() {
      if (confirm('Are you sure you want to delete the entire category?')) {
        const cardsToDelete = this.cards.filter(card => card.Category === this.selectedCategory);
        for (const card of cardsToDelete) {
          await deleteDoc(doc(db, 'cards', card.id));
        }
        alert('Category deleted');
        this.loadCategories(); // Refresh categories
      }
    },
    async updateCardImage(cardId, imageUrl) {
      try {
        if (!cardId || !imageUrl) {
          throw new Error('Card ID and Image URL are required');
        }
        await updateDoc(doc(db, 'cards', cardId), {Image: imageUrl});
        console.log('Card image updated successfully in Firestore.');
      } catch (error) {
        console.error('Error updating card image in Firestore:', error);
      }
    },

    // Delete the entire subcategory
    async deleteSubcategory() {
      if (confirm('Are you sure you want to delete the entire subcategory?')) {
        const cardsToDelete = this.cards.filter(card => card.Subcategory === this.selectedSubcategory);
        for (const card of cardsToDelete) {
          await deleteDoc(doc(db, 'cards', card.id));
        }
        alert('Subcategory deleted');
        this.loadSubcategories(this.selectedCategory); // Refresh subcategories
      }
    },

    // Create a new card
    async createNewCard() {
      try {
        if (!this.editedCategory || !this.editedSubcategory) {
          alert("Please enter both Category and Subcategory.");
          return;
        }

        const newCard = {
          ...this.editedCard,
          Category: this.editedCategory,
          Subcategory: this.editedSubcategory,
        };

        delete newCard.firestoreId; // Remove Firestore ID if present

        await addDoc(collection(db, "cards"), newCard);
        alert("New card created successfully.");

        // Reload cards for the updated category/subcategory
        await this.loadCards(this.editedCategory, this.editedSubcategory);
      } catch (error) {
        console.error("Error creating new card:", error);
        alert("Failed to create new card. Please try again.");
      }
    },

    async updateCard() {
      try {
        if (!this.editedCard.firestoreId) {
          alert("No card selected to update.");
          return;
        }

        if (!this.editedCategory || !this.editedSubcategory) {
          alert("Please enter both Category and Subcategory.");
          return;
        }

        const newCard = {
          ...this.editedCard,
          Category: this.editedCategory,
          Subcategory: this.editedSubcategory,
        };

        delete newCard.firestoreId; // Remove Firestore ID before creating a new card

        // Create a new card with updated category and subcategory
        await addDoc(collection(db, "cards"), newCard);
        alert("Card updated successfully as a new entry.");

        // Reload cards for the updated category/subcategory
        await this.loadCards(this.editedCategory, this.editedSubcategory);
      } catch (error) {
        console.error("Error updating card:", error);
        alert("Failed to update card. Please try again.");
      }
    },
  },

  async mounted() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      console.log("Authenticated user ID:", user.uid);
    } else {
      console.error("No authenticated user found.");
    }
    await this.authenticateUser(); // Authenticate user if needed
    await this.loadCategories(); // Ensure categories are loaded on mount

    // Automatically load subcategories if a category is already selected
    if (this.selectedCategory) {
      await this.loadSubcategories(this.selectedCategory);
    }
  },

};

</script>

<style scoped>
.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.header-image {
  max-width: 220%;
  margin-left: 50px;
  height: auto;
  max-height: 100px;
  object-fit: contain;
}

.flashcard {
  width: 100%; /* Full width of the page or container */
  max-width: 450px; /* Same as inputs for alignment */
  margin: 0 auto; /* Center align the container */
  padding: 0; /* Remove extra padding */
  box-sizing: border-box; /* Consistent box-sizing */
}

.item-input,
.description-input,
.details-input {
  width: 100%; /* Full width of parent container */
  max-width: 450px; /* Same maximum width for all */
  padding: 10px; /* Ensure padding is consistent */
  margin: 0 auto 10px auto; /* Center horizontally and same bottom margin */
  border: 2px solid #000; /* Same border */
  border-radius: 5px; /* Same border radius */
  box-sizing: border-box; /* Include padding and border in width calculation */
}

.item-input {
  font-size: 2em;
}

.description-input {
  font-size: 1.2em;
  height: 100px;
}

.details-input {
  font-size: 1em;
  height: 250px;
}

.image-container {
  display: flex;
  flex-direction: column;
  width: 200px; /* Updated width */
  align-items: center;
  justify-content: center; /* Ensure the content is vertically centered */
  border: 2px solid #000;
  padding: 10px;
  border-radius: 10px;
  margin: 0 auto; /* Center the container horizontally */
  background-color: #fff; /* Optional: Add a background for better visibility */
}

.card-image {
  max-height: 200px; /* Adjust as needed for proportion */
  max-width: 100%;
  object-fit: contain; /* Preserve aspect ratio */
  cursor: pointer;
}


.category-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.select-category-button {
  flex: 1;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-align: center;
  background-color: darkblue;
  color: white;
}

.control-button,
.select-category-button {
  flex: 1; /* Allow buttons to scale evenly */
  min-width: 120px; /* Ensure a consistent minimum width */
  max-width: 150px; /* Limit maximum width */
  padding: 10px; /* Consistent padding */
  border-radius: 5px; /* Rounded edges */
  border: none; /* Remove borders */
  cursor: pointer;
  text-align: center;
  font-size: 1em; /* Adjust font size for readability */
}

.control-button.dark-green {
  background-color: darkgreen;
  color: white;
}

.control-button.orange {
  background-color: darkorange;
  color: black;
}

.control-button.red {
  background-color: darkred;
  color: white;
}

.control-button.green {
  background-color: darkgreen;
  color: white;
}

.control-button.purple {
  background-color: purple;
  color: white;
}

.select-category-button {
  background-color: darkblue;
  color: white;
}

.controls {
  display: flex;
  justify-content: space-evenly; /* Equal spacing between buttons */
  flex-wrap: wrap; /* Allow buttons to wrap to a new line if necessary */
  gap: 10px; /* Add uniform spacing between buttons */
  width: 100%;
  max-width: 450px; /* Ensure consistency with other elements */
  margin-top: 10px;
}

.dropdown-content {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  max-width: 340px;
  position: absolute;
  z-index: 10;
}

.search-input {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
