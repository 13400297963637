import { createStore } from 'vuex';

const store = createStore({
  state: {
    appState: {
      currentPage: 'defaultPage',
      formData: {}
    }
  },
  mutations: {
    setAppState(state, newState) {
      state.appState = newState;
    }
  },
  actions: {
    updateAppState({ commit }, newState) {
      commit('setAppState', newState);
    }
  },
  getters: {
    getAppState: (state) => state.appState
  }
});

export default store;
