<template>
  <div class="flashcard">
    <!-- Header Section -->
    <div class="header-container">
      <img alt="Vue logo" src="../assets/web_header-flash.png" class="header-image">
    </div>
    <!-- Image Upload Section -->
    <div class="upload-back-image">
      <input type="file" @change="openImagePopup" accept="image/*" id="fileInput" style="display: none;" />
      <label for="fileInput" class="custom-file-button">Choose Card Image</label>
    </div>
    <!-- Item Section (No Scroll) -->
    <div @click="flipCard('item')" ref="item" class="card-section flipped-item" :class="{'flipped': flipped.item}">
      <div class="card-front">
        <p>{{ currentCard?.Item || 'No Item Available' }}</p>
      </div>
      <div class="card-back" :style="getBackImageStyle('item')"></div>
    </div>

    <!-- Description Section (Scrollable, text starts at top) -->
    <div @click="flipCard('description')" ref="description" class="card-section description-section flipped-description" :class="{'flipped': flipped.description}">
      <div class="card-front">
        <div class="card-content scrollable">
          <p>{{ currentCard?.Description || 'No Description Available' }}</p>
        </div>
      </div>
      <div class="card-back" :style="getBackImageStyle('description')"></div>
    </div>

    <!-- Details Section (Scrollable, text starts at top) -->
    <div @click="flipCard('details')" ref="details" class="card-section details-section flipped-details" :class="{'flipped': flipped.details}">
      <div class="card-front">
        <div class="card-content scrollable">
          <p>{{ currentCard?.Details || 'No Details Available' }}</p>
        </div>
      </div>
      <div class="card-back" :style="getBackImageStyle('details')"></div>
    </div>

    <!-- Image Section -->
    <div @click="flipCard('image')" ref="image" class="card-section image-section flipped-image" :class="{'flipped': flipped.image}">
      <div class="card-front">
        <img v-if="currentCard?.Image" :src="currentCard.Image" alt="Card image" />
        <p v-else>{{ currentCard?.Item || 'No Image Available' }}</p>
      </div>
      <div class="card-back" :style="getBackImageStyle('image')"></div>
    </div>

    <!-- Popup for Adjusting Image -->
    <div v-if="showPopup" class="popup">
      <div class="popup-content">
        <h3>Adjust Image for Card</h3>
        <div class="image-adjustment-container">
          <div
            class="image-wrapper"
            :style="{ backgroundImage: `url(${backImage})`, backgroundSize: backgroundSize, backgroundPositionX: `${imagePosX}px`, backgroundPositionY: `${imagePosY}px` }"
            @mousedown="startDrag"
            @mousemove="onDrag"
            @mouseup="stopDrag"
            @wheel="onWheel"
          >
            <div class="split-line" v-for="line in splitLines" :key="line"></div>
          </div>
        </div>
        <button @click="splitImage">Split Image</button>
        <button @click="closePopup">Cancel</button>
      </div>
    </div>

    <!-- Dropdown selection for Category, Subcategory, and Difficulty with Search -->
    <div class="category-selection-row">
      <!-- Category Dropdown with Search -->
      <div class="dropdown-wrapper">
        <button @click="toggleCategoryDropdown" class="select-category-button">
          {{ selectedCategory ? `${selectedCategory} (${categoryCount})` : 'Category' }}
        </button>
        <div v-if="showingCategory" class="dropdown-content">
          <input type="text" v-model="categorySearch" placeholder="Search Category" class="search-input"/>
          <select v-model="selectedCategory" @change="selectCategory">
            <option value="" disabled>Select a category</option>
            <option v-for="category in filteredCategories" :key="category" :value="category">{{ category }}</option>
          </select>
        </div>
      </div>

      <!-- Subcategory Dropdown with Search -->
      <div class="dropdown-wrapper">
        <button @click="toggleSubcategoryDropdown" class="select-category-button" :disabled="!selectedCategory">
          {{ selectedSubcategory ? `${selectedSubcategory} (${subcategoryCount})` : 'Subcategory' }}
        </button>
        <div v-if="showingSubcategory" class="dropdown-content">
          <input type="text" v-model="subcategorySearch" placeholder="Search Subcategory" class="search-input"/>
          <select v-model="selectedSubcategory" @change="selectSubcategory">
            <option value="" disabled>Select a subcategory</option>
            <option v-for="subcategory in filteredSubcategories" :key="subcategory" :value="subcategory">{{ subcategory }}</option>
          </select>
        </div>
      </div>

      <!-- Difficulty Dropdown -->
      <div class="dropdown-wrapper">
        <button @click="toggleDifficultyDropdown" class="select-category-button" :disabled="!selectedSubcategory">
          {{ selectedDifficulty ? `${selectedDifficulty} (${difficultyCount})` : 'Difficulty' }}
        </button>
        <div v-if="showingDifficulty" class="dropdown-content">
          <select v-model="selectedDifficulty" @change="selectDifficulty">
            <option value="" disabled selected>Select Difficulty</option>
            <option v-for="difficulty in difficulties" :key="difficulty" :value="difficulty">
              {{ difficulty }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- Next and Previous controls (buttons below the image) -->
    <div class="controls">
      <button @click="previousCard" class="control-button orange">Previous</button>
      <button @click="goToEditPage" class="control-button purple">Edit</button> <!-- New Edit Button -->
      <button @click="nextCard" class="control-button orange">Next</button>
    </div>

    <!-- Difficulty Buttons -->
    <div class="difficulty-buttons">
      <button
        @click="changeDifficulty('Easy')"
        :class="['control-button', 'dark-red', { green: currentCard?.Difficulty === 'Easy' }]">
        Easy
      </button>
      <button
        @click="changeDifficulty('Medium')"
        :class="['control-button', 'dark-red', { green: currentCard?.Difficulty === 'Medium' }]">
        Medium
      </button>
      <button
        @click="changeDifficulty('Hard')"
        :class="['control-button', 'dark-red', { green: currentCard?.Difficulty === 'Hard' }]">
        Hard
      </button>
    </div>

    <!-- Audio Section -->
    <div v-if="currentCard" class="audio-section">
      <button
        @click="toggleAudioPlayback"
        :class="['control-button', hasAudio ? (isPlayingAudio ? 'dark-red' : 'dark-green') : 'dark-red']"
        :disabled="!currentCard || !currentCard.Item"
      >
        {{ isPlayingAudio ? 'Stop Audio' : 'Play Audio' }}
      </button>

      <button
        @click="startRecording"
        :class="['control-button', hasAudio ? 'dark-red' : 'dark-green']"
        :disabled="recording"
      >
        {{ hasAudio ? 'Overwrite Audio' : 'Record Audio' }}
      </button>

      <button
        @click="stopRecording"
        class="control-button red"
        v-if="recording"
      >
        Stop Recording
      </button>

      <button
        @click="uploadAudio"
        class="control-button orange"
        v-if="recordedAudio && !recording"
      >
        Upload Audio
      </button>
    </div>
  </div>
</template>


<script>
import { db, storage } from '../firebaseConfig';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Capacitor } from '@capacitor/core'; // Add this line

export default {
  name: 'FlashcardView',
  data() {
    return {
      currentCard: { Item: '', Description: '', Details: '', Image: '' },
      sectionImages: { item: '', description: '', details: '', image: '' }, // Initialize
      flipped: {
        item: false,
        description: false,
        details: false,
        image: false,
      },
      audioAvailable: false,
      recording: false,
      recordedAudio: null,
      mediaRecorder: null,
      audioChunks: [],
      audioPlayer: null, // To store the audio object
      isPlayingAudio: false, // Track if audio is currently playing

      backImage: '', // Image for the back of the card
      showPopup: false, // Popup for image adjustment
      splitLines: [40, 100, 240], // Heights for each section
      dragStartX: 0,
      dragStartY: 0,
      imagePosX: 0,
      imagePosY: 0,
      dragging: false,
      backgroundSize: 'cover', // Initial background size for the image
      scale: 1, // Scale for zoom
      categories: [],
      subcategories: [],
      categorySearch: '', // Search field for category
      subcategorySearch: '', // Search field for subcategory
      selectedCategory: '',
      selectedSubcategory: '',
      categoryCount: 0,
      subcategoryCount: 0,
      selectedDifficulty: 'All',
      difficultyCount: 0,
      difficulties: ['Easy', 'Medium', 'Hard', 'All', 'Random'],
      showingCategory: false,
      showingSubcategory: false,
      showingDifficulty: false,
    };
  },

  computed: {
    appState() {
        return this.$store.state.appState;
    },
    hasAudio() {
      // Returns true if the current card has a non-empty audio field
      return this.currentCard?.audio && this.currentCard.audio.trim() !== '';
    },
    // Alphabetically sorted and filtered categories based on the search input
    filteredCategories() {
      return this.categories
        .filter(category => category.toLowerCase().includes(this.categorySearch.toLowerCase()))
        .sort((a, b) => a.localeCompare(b));
    },
    // Alphabetically sorted and filtered subcategories based on the search input
    filteredSubcategories() {
      return this.subcategories
        .filter(subcategory => subcategory.toLowerCase().includes(this.subcategorySearch.toLowerCase()))
        .sort((a, b) => a.localeCompare(b));
    }
  },

  methods: {
    updateAppState(newState) {
        this.$store.commit('setAppState', newState);
    },
    saveCurrentState() {
        const stateToSave = {
            currentPage: this.currentPage,
            formData: this.formData,
        };
        this.$store.commit('setAppState', stateToSave);
    },
    goToEditPage() {
      this.$router.push({name: 'EditFlashcard', params: {cardId: this.currentCard.id}});
    },
    // Method to request microphone permission
    async requestMicrophonePermission() {
      try {
        if (Capacitor.isNativePlatform()) {
          // Native platform (Android/iOS) permission handling
          const permissionStatus = await Permissions.query({name: 'microphone'});

          if (permissionStatus.state === 'granted') {
            console.log('Microphone permission already granted.');
          } else {
            const result = await Permissions.request({name: 'microphone'});
            if (result.state === 'granted') {
              console.log('Microphone permission granted.');
            } else {
              console.log('Microphone permission denied.');
              alert('Microphone permission is required to record audio.');
            }
          }
        } else if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          // Browser-based permission handling
          console.log('Requesting web-based microphone permission.');
          await navigator.mediaDevices.getUserMedia({audio: true});
          console.log('Microphone permission granted for the web.');
        } else {
          throw new Error('Media devices or getUserMedia is not supported by this browser.');
        }
      } catch (error) {
        console.error('Error requesting microphone permission:', error);
        alert('Failed to request microphone permission. Please try again.');
      }
    },

    flipCard(section) {
      this.flipped[section] = !this.flipped[section];
    },

    // Opens the popup for image adjustment
    openImagePopup(event) {
      const file = event.target.files[0];
      if (file) {
        this.backImage = URL.createObjectURL(file);
        this.showPopup = true;
      }
    },

    // Start dragging the image in the popup
    startDrag(event) {
      this.dragging = true;
      this.dragStartX = event.clientX - this.imagePosX;
      this.dragStartY = event.clientY - this.imagePosY;
    },

    // Handle image dragging in the popup
    onDrag(event) {
      if (this.dragging) {
        this.imagePosX = event.clientX - this.dragStartX;
        this.imagePosY = event.clientY - this.dragStartY;
      }
    },

    // Stop dragging the image
    stopDrag() {
      this.dragging = false;
    },

    // Zoom in or out on the image with the mouse wheel
    onWheel(event) {
      event.preventDefault();
      const scaleFactor = 0.1;
      if (event.deltaY < 0) {
        this.scale += scaleFactor;
      } else {
        this.scale -= scaleFactor;
      }
      this.backgroundSize = `${this.scale * 100}%`;
    },

    // Logic to split the image into four sections
    splitImage() {
      const canvas = document.createElement('canvas');
      const img = new Image();
      img.src = this.backImage;

      img.onload = () => {
        const [itemHeight, descriptionHeight, detailsHeight, imageHeight] = [40, 60, 140, 200];
        const totalHeight = itemHeight + descriptionHeight + detailsHeight + imageHeight;
        const width = img.width;

        // Make sure canvas matches the image size
        canvas.width = width;
        canvas.height = totalHeight;

        const ctx = canvas.getContext('2d');

        // Draw each part of the image to the canvas
        ctx.drawImage(img, 0, 0, width, itemHeight, 0, 0, width, itemHeight); // Item section
        ctx.drawImage(img, 0, itemHeight, width, descriptionHeight, 0, itemHeight, width, descriptionHeight); // Description section
        ctx.drawImage(img, 0, itemHeight + descriptionHeight, width, detailsHeight, 0, itemHeight + descriptionHeight, width, detailsHeight); // Details section
        ctx.drawImage(img, 0, itemHeight + descriptionHeight + detailsHeight, width, imageHeight, 0, itemHeight + descriptionHeight + detailsHeight, width, imageHeight); // Image section

        // Convert canvas to base64 image and assign to respective sections
        this.sectionImages = {
          item: canvas.toDataURL('image/png', 0, 0, width, itemHeight),
          description: canvas.toDataURL('image/png', 0, itemHeight, width, descriptionHeight),
          details: canvas.toDataURL('image/png', 0, itemHeight + descriptionHeight, width, detailsHeight),
          image: canvas.toDataURL('image/png', 0, itemHeight + descriptionHeight + detailsHeight, width, imageHeight)
        };

        // Close the popup
        this.showPopup = false;
      };
    },

    // Style for the card back image based on section
    getBackImageStyle(section) {
      const positions = {
        item: '0% 0%',
        description: '0% 40px',
        details: '0% 100px',
        image: '0% 240px'
      };

      // Return the sliced image for each section
      return {
        backgroundImage: `url(${this.sectionImages[section] || this.backImage})`,
        backgroundPosition: positions[section],
        backgroundSize: this.backgroundSize
      };
    },

    showCategoryDropdown() {
      this.showingCategory = true;
    },
    showSubcategoryDropdown() {
      this.showingSubcategory = true;
    },
    showDifficultyDropdown() {
      this.showingDifficulty = true;
    },
    toggleCategoryDropdown() {
      this.showingCategory = !this.showingCategory;
      this.showingSubcategory = false;
      this.showingDifficulty = false;
    },
    toggleSubcategoryDropdown() {
      this.showingSubcategory = !this.showingSubcategory;
      this.showingCategory = false;
      this.showingDifficulty = false;
    },
    toggleDifficultyDropdown() {
      this.showingDifficulty = !this.showingDifficulty;
      this.showingCategory = false;
      this.showingSubcategory = false;
    },

    async loadCategories() {
      const cardsCollection = collection(db, 'cards');
      const snapshot = await getDocs(cardsCollection);
      const categoriesSet = new Set();
      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (data.Category) {
          categoriesSet.add(data.Category);
        }
      });
      this.categories = Array.from(categoriesSet);
    },
    async loadCards(category, subcategory, difficulty = 'All') {
      const cardsCollection = collection(db, 'cards');
      let q = query(
          cardsCollection,
          where('Category', '==', category),
          where('Subcategory', '==', subcategory)
      );

      if (difficulty !== 'All') {
        q = query(q, where('Difficulty', '==', difficulty));
      }

      const snapshot = await getDocs(q);

      this.cards = snapshot.docs.map((doc) => ({
        id: doc.id,  // Include the Firestore document ID here
        ...doc.data(),
      }));

      if (this.cards.length > 0) {
        this.currentCardIndex = 0;
        this.currentCard = {...this.cards[0]};
      } else {
        this.currentCard = null;
      }

      this.difficultyCount = this.cards.length;

      // Check if the audio field is available
      this.audioAvailable = !!this.currentCard?.audio;
    },

    selectDifficulty(event) {
      const selectedDifficulty = event.target.value;
      if (!selectedDifficulty) return;
      this.selectedDifficulty = selectedDifficulty;
      this.showingDifficulty = false;
      if (selectedDifficulty === 'Random') {
        this.selectRandomCard();
      } else {
        this.loadCards(this.selectedCategory, this.selectedSubcategory, selectedDifficulty);
      }
    },

    async selectCategory() {
      this.selectedSubcategory = '';
      this.selectedDifficulty = 'All';
      this.showingCategory = false;
      await this.loadSubcategories(this.selectedCategory);
      await this.updateCategoryAndSubcategoryCounts();
    },
    selectSubcategory(event) {
      this.selectedSubcategory = event.target.value || this.subcategories[0];
      this.showingSubcategory = false;
      this.loadCards(this.selectedCategory, this.selectedSubcategory, this.selectedDifficulty).then(() => {
        this.currentCardIndex = 0;
        this.currentCard = {...this.cards[0]};
        this.updateCategoryAndSubcategoryCounts();
      });
    },
    async loadSubcategories(category) {
      const cardsCollection = collection(db, 'cards');
      const q = query(cardsCollection, where('Category', '==', category));
      const snapshot = await getDocs(q);
      const subcategoriesSet = new Set();
      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (data.Subcategory) {
          subcategoriesSet.add(data.Subcategory);
        }
      });
      this.subcategories = Array.from(subcategoriesSet);
    },
    async updateCategoryAndSubcategoryCounts() {
      const cardsCollection = collection(db, 'cards');
      const q = query(cardsCollection, where('Category', '==', this.selectedCategory));
      const snapshot = await getDocs(q);
      this.categoryCount = snapshot.docs.length;
      this.subcategoryCount = snapshot.docs.filter((doc) => doc.data().Subcategory === this.selectedSubcategory).length;
    },
    nextCard() {
      if (this.selectedDifficulty === 'Random') {
        this.selectRandomCard();
      } else {
        this.currentCardIndex = (this.currentCardIndex + 1) % this.cards.length;
        this.currentCard = {...this.cards[this.currentCardIndex]};
      }
    },
    previousCard() {
      if (this.currentCardIndex === 0) {
        this.currentCardIndex = this.cards.length - 1;
      } else {
        this.currentCardIndex -= 1;
      }
      this.currentCard = {...this.cards[this.currentCardIndex]};
    },

    async changeDifficulty(difficulty) {
      if (this.currentCard?.Difficulty !== difficulty) {
        if (confirm(`Change difficulty to ${difficulty}?`)) {
          try {
            const cardId = this.currentCard?.id;
            if (!cardId) {
              throw new Error("Card document ID is missing.");
            }
            const cardDocRef = doc(db, "cards", cardId);
            await updateDoc(cardDocRef, {Difficulty: difficulty});
            this.currentCard.Difficulty = difficulty;
            if (this.selectedDifficulty === 'All' || this.selectedDifficulty === 'Random') {
              return;
            }
            await this.loadCards(this.selectedCategory, this.selectedSubcategory, this.selectedDifficulty);
            this.currentCardIndex = 0;
            this.currentCard = {...this.cards[0]};
          } catch (error) {
            console.error("Error updating difficulty:", error);
            alert("Failed to update the difficulty. Please try again.");
          }
        }
      }
    },

    selectRandomCard() {
      const hardCards = this.cards.filter((card) => card.Difficulty === 'Hard');
      const mediumCards = this.cards.filter((card) => card.Difficulty === 'Medium');
      const easyCards = this.cards.filter((card) => card.Difficulty === 'Easy');

      // Fallback for case where there are no cards of a difficulty
      const allCards = [...hardCards, ...mediumCards, ...easyCards];
      if (allCards.length === 0) {
        console.log("No cards available for random selection.");
        return;
      }

      // Randomly choose a difficulty based on the weight: 6 Hard, 3 Medium, 1 Easy
      const random = Math.random();
      let selectedDifficulty;

      if (random < 0.6) {
        selectedDifficulty = 'Hard';
      } else if (random < 0.9) {
        selectedDifficulty = 'Medium';
      } else {
        selectedDifficulty = 'Easy';
      }

      // Select cards based on the randomly chosen difficulty
      let selectedCards;
      if (selectedDifficulty === 'Hard' && hardCards.length > 0) {
        selectedCards = hardCards;
      } else if (selectedDifficulty === 'Medium' && mediumCards.length > 0) {
        selectedCards = mediumCards;
      } else if (selectedDifficulty === 'Easy' && easyCards.length > 0) {
        selectedCards = easyCards;
      } else {
        selectedCards = allCards; // Fallback to all cards if none match the difficulty
      }

      // Randomly select a card from the selected difficulty's pool
      const randomCardIndex = Math.floor(Math.random() * selectedCards.length);
      this.currentCard = selectedCards[randomCardIndex];
    },

    // Method to toggle audio playback (play/stop)
    toggleAudioPlayback() {
      if (this.isPlayingAudio) {
        this.stopAudioPlayback(); // Stop if audio is playing
      } else {
        this.playAudio(); // Start playing if no audio is playing
      }
    },

    // Method to play audio
    async playAudio() {
      try {
        if (this.currentCard?.audio) {
          const audioUrl = this.currentCard.audio;
          this.audioPlayer = new Audio(audioUrl);
          this.isPlayingAudio = true;

          // Play audio
          this.audioPlayer.play().then(() => {
            console.log('Audio is playing.');
          }).catch((error) => {
            console.error("Error playing audio:", error);
            alert("Unable to play audio. Please try again.");
            this.isPlayingAudio = false;
          });

          // Reset isPlayingAudio when the audio finishes playing
          this.audioPlayer.onended = () => {
            this.isPlayingAudio = false;
          };

        } else {
          alert("No audio available for this card.");
        }
      } catch (error) {
        console.error("Error in playing audio:", error);
        alert("Failed to play audio.");
      }
    },
    // Method to stop audio playback
    stopAudioPlayback() {
      if (this.audioPlayer) {
        this.audioPlayer.pause(); // Pause the audio
        this.audioPlayer.currentTime = 0; // Reset the audio to the start
        this.isPlayingAudio = false; // Update the isPlayingAudio state
        console.log('Audio playback stopped.');
      }
    },
    // Method to handle starting and stopping audio recording
    // Method to start recording audio
    async startRecording() {
      // If audio exists, confirm overwrite
      if (this.hasAudio && !confirm("Audio already exists. Do you want to overwrite it?")) {
        return;
      }

      try {
        // Request microphone access and start recording
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];
        this.recording = true;

        // Start recording
        this.mediaRecorder.start();
        console.log('Recording started.');

        // Update button state to allow stopping recording
        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, {type: 'audio/wav'});
          this.recordedAudio = audioBlob;
          this.recording = false;
          console.log('Recording stopped.');
        };
      } catch (error) {
        console.error('Error accessing microphone:', error);
        alert('Failed to access microphone. Please check your microphone permissions.');
      }
    },

    stopRecording() {
      // Stop the media recorder if it’s recording
      if (this.mediaRecorder && this.recording) {
        this.mediaRecorder.stop();
        console.log('Recording stopped.');
        this.recording = false;
      }
    },

    async uploadAudio() {
      if (this.recordedAudio && this.currentCard.id) {
        try {
          const storageRef = ref(storage, `audio/${this.currentCard.Item}.wav`);
          await uploadBytes(storageRef, this.recordedAudio);
          const audioUrl = await getDownloadURL(storageRef);

          // Ensure the currentCard has an id
          if (!this.currentCard.id) {
            throw new Error("Card document ID is missing.");
          }

          // Update Firestore document with new audio URL
          const cardDocRef = doc(db, 'cards', this.currentCard.id);
          await updateDoc(cardDocRef, {audio: audioUrl});

          // Update local card data with the new audio URL
          this.currentCard.audio = audioUrl;
          this.recordedAudio = null;

          alert('Audio uploaded and saved successfully.');
        } catch (error) {
          console.error("Error uploading audio:", error);
          alert("Failed to upload audio. Please try again.");
        }
      } else {
        alert("No audio recorded or card document is missing an ID.");
      }
    },
  },

  async mounted() {
    console.log("Component is mounted");
    this.loadCategories();
    await this.loadCategories();
    if (this.selectedCategory && this.selectedSubcategory) {
      await this.loadCards(this.selectedCategory, this.selectedSubcategory, 'All');
      this.currentCardIndex = 0;
      this.currentCard = this.cards[this.currentCardIndex];
    }
    const savedState = this.$store.state.appState;
    if (savedState) {
        this.currentPage = savedState.currentPage || 'defaultPage';
        this.formData = savedState.formData || {};
    }
  },

};
</script>

<style scoped>
.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.header-image {
  max-width: 220%;
  margin-left: 50px;
  height: auto;
  max-height: 100px;
  object-fit: contain;
}

.flashcard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 450px;
  margin: 0 auto;
}

.card-section {
  width: 100%; /* Full width of parent */
  max-width: 350px; /* Maximum width for each card section */
  min-height: 40px; /* Minimum height */
  margin: 3px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.6s ease-in-out;
  border: 2px solid #000; /* Consistent black border */
  padding: 5px;
  position: relative;
  perspective: 1000px; /* 3D perspective for flip effect */
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff; /* Ensure white background */
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide the back when flipped */
  transition: transform 0.6s ease-in-out;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center;      /* Vertically center the content */
  text-align: center;       /* Ensure text is centered */
  padding: 10px;
  box-sizing: border-box; /* Ensure padding doesn’t affect size */
}

/* Card content (default, no scroll) */
.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
}

/* Scrollable content for description and details */
.card-content.scrollable {
  justify-content: flex-start; /* Align text to start (top) */
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 100%;
  padding: 0; /* Remove padding to avoid extra space */
  text-align: center;
  word-wrap: break-word;
  box-sizing: border-box;
}

.card-content.scrollable p {
  margin: 0; /* Ensure no margin on paragraph */
  padding: 0 10px; /* Add horizontal padding only */
}

.card-front {
  background-color: #f9f9f9; /* Light background for front */
}

.card-back {
  background-color: #e0e0e0; /* Slightly darker background for back */
  transform: rotateY(180deg); /* Default flipped state for the back */
}

.flipped .card-front {
  transform: rotateY(180deg); /* Rotate front when flipped */
}

.flipped .card-back {
  transform: rotateY(0deg); /* Rotate back to show when flipped */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  width: 300px;
  height: auto;
}

.popup-content {
  text-align: center;
}

.image-adjustment-container {
  position: relative;
  width: 100%;
  height: 400px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: move;
  transition: background-size 0.2s ease;
}

.split-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: red;
}

.split-line:nth-child(1) {
  top: 40px;
}

.split-line:nth-child(2) {
  top: 100px;
}

.split-line:nth-child(3) {
  top: 240px;
}

button {
  margin-top: 0px;
  padding: 8px 12px;
  cursor: pointer;
}

.back-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: 200% 200%;
}

.flipped-item .card-back {
  background-position: 0% 0%;
}

.flipped-description .card-back {
  background-position: 100% 0%;
}

.flipped-details .card-back {
  background-position: 0% 100%;
}

.flipped-image .card-back {
  background-position: 100% 100%;
}

.description-section {
  height: 60px;
  width: 350px;
}

.description-section p {
  font-size: 18px;
}

.details-section {
  height: 140px;
  width: 350px;
}

.details-section p {
  font-size: 14px;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.image-preview {
  position: relative;
  width: 100%;
  height: 440px;
  overflow: hidden;
}

.resizable-image {
  width: 100%;
  height: auto;
  max-height: 440px;
}

.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.grid-line {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: red;
  pointer-events: none;
}

.image-section {
  height: 200px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  border-radius: 10px;
  background-color: white;
}

.image-section img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.upload-back-image button {
  background-color: darkblue;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.custom-file-button {
  background-color: darkblue;
  color: white;
  padding: 5px;
  border-radius: 5px;
  background-color: rebeccapurple;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
}

.controls,
.difficulty-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  margin-top: 0px;
}

.control-button,
.select-category-button {
  width: 100px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  text-align: center;
  margin: 5px 5px;
}

.control-button.dark-green {
  background-color: darkgreen;
  color: white;
}

.control-button.red {
  background-color: red;
  color: white;
}

.select-category-button {
  background-color: darkblue;
  color: white;
}

.control-button.purple {
  background-color: purple;
  color: white;
}

.control-button.dark-red {
  background-color: darkred;
  color: white;
}

.control-button.green {
  background-color: green;
  color: white;
}

.control-button.orange {
  background-color: darkorange;
  color: black;
}

.custom-select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  margin-top: 0px;
}

.dropdown select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  bottom: 100%; /* This makes the dropdown pop upwards */
  left: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.dropdown-content select {
  width: 100%;
  padding: 8px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.dropdown-content select:focus {
  outline: none;
}

.select-category-button {
  width: 120px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  text-align: center;
  background-color: darkblue;
  color: white;
}

.custom-select:focus {
  outline: none;
  border-color: darkblue;
}

select option {
  background-color: white;
  color: black;
}

.category-selection-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-content {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.select-category-button {
  width: 120px;
  padding: 10px;
  border-radius: 4px;
  background-color: darkblue;
  color: white;
  border: none;
  cursor: pointer;
}

.search-input {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
